import { useEffect, useState } from "react";
import { BigProgress } from "../Components/Basics";
import Main from "../Components/Main";
import { Navigate, useNavigate } from "react-router-dom";
import API from "../Support/API";

const Logout = ({updateLoggedIn}) => {

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {

        updateLoggedIn();

        API.get("logout")
        .then((e) => {
            setIsLoading(false);
        });

    },[]);

    return (

        isLoading
        
        ? 

        <BigProgress />

        :

        <Navigate to="/login" />
    )
}

export default Logout;