import Main from "../Components/Main";
import { H3, DangerAlert, DashboardCard, APIProgress, LinearProgress } from "../Components/Basics";
import { GreenBadge } from "../Components/Badges";
import { useEffect, useRef, useState } from "react";
import API from "../Support/API";

import { RiSignalTowerFill, RiParkingBoxLine, RiTimeLine, RiEdit2Fill } from "react-icons/ri";
import dateFormatter from "dateformat";
import { Link } from "react-router-dom";
import {AiOutlineEdit} from "react-icons/ai";

const Dashboard = () => {

    const [cardsValue, setCardsValue] = useState({
        totalRuns: 0,
        totalPrompts: 0,
        thisMonthPrompts: 0
    });

    const [isLoading, setIsLoading] = useState(true);

    const [prompts, setPrompts] = useState(null);

    const [apiKey, setApiKey] = useState(null);

    const formatNum = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number.toLocaleString();
        }
    }

    useEffect(() => {

        API.get('dashboard')
            .then((e) => {

                setCardsValue({
                    totalRuns: e.data.totalRuns,
                    totalPrompts: e.data.totalPrompts,
                    thisMonthPrompts: e.data.thisMonthPrompts,
                });

                setPrompts(e.data.recentPrompts);

                setApiKey(e.data.apiKey);

                setIsLoading(false);

            })
            .catch((err) => {

            })

    }, []);

    const dateFormat = (date) => {

        const now = new Date(date);

        const newDate = dateFormatter(now, "mmmm dd, yyyy");

        return newDate;
    }


    return (

        <Main active="dashboard">

            {
                isLoading ?

                    <APIProgress />


                    :


                    <div className="max-w-6xl mx-auto px-3">

                        <H3 className="mt-4">Dashboard</H3>

                        <div className="flex items-center justify-center flex-wrap w-full">

                            <DashboardCard

                                title={'Runs'}
                                value={formatNum(cardsValue.totalRuns)}
                                icon={<RiSignalTowerFill size={25} />}

                            />

                            <DashboardCard

                                title={'Total Prompts'}
                                value={formatNum(cardsValue.totalPrompts)}
                                icon={<RiParkingBoxLine size={25} />}

                            />

                            <DashboardCard

                                title={'This Month Prompts'}
                                value={formatNum(cardsValue.thisMonthPrompts)}
                                icon={<RiTimeLine size={25} />}

                            />
                        </div>

                        {
                            apiKey === null &&
                            <div className="my-5">
                                <DangerAlert>Please add your OpenAI API Key from <Link className="font-bold" to={'/profile'}>profile</Link> page.</DangerAlert>
                            </div>
                        }

                        {

                            prompts !== null && prompts.length > 0

                            &&

                            <>

                                <H3 className="mt-5">Recent Prompts</H3>

                                <div className="relative overflow-x-auto my-5">

                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">

                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Total Runs
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Output Words
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Time Saved
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Rank
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Modified At
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {

                                                prompts !== null && prompts.map((e) => {

                                                    //if status is training, increase on training value

                                                    return (
                                                        <tr key={e.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {e.name}
                                                            </th>

                                                            <td className="px-6 py-4">
                                                                {e.runs}
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                <GreenBadge
                                                                    text={formatNum(e.words)}
                                                                />
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                {e.minSaved} Mins
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                <div className="mb-1">{e.rank}</div>
                                                                <LinearProgress percentage={e.rank} />
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                {dateFormat(e.updatedAt)}
                                                            </td>

                                                            <td className="px-6 py-4">

                                                                <div className="flex">

                                                                    <Link to={"/prompts/update/" + e.id}><AiOutlineEdit title="Edit" color="gray" className="mr-2" size={20} /></Link>


                                                                </div>

                                                            </td>


                                                        </tr>
                                                    )

                                                })
                                            }



                                        </tbody>

                                    </table>

                                </div>

                            </>
                        }



                    </div>

            }




        </Main>

    );


}

export default Dashboard;