import { useEffect, useState } from "react";
import { BigProgress, Button, H2, Link } from "../Components/Basics";
import NavBarAuth from "../Components/NavBarAuth";
import { useNavigate, useParams } from "react-router-dom";
import API from "../Support/API";

const SharePrompt = ({ isLoggedIn }) => {

    const {id} = useParams();
    const [data, setData] = useState({
        authorName : '',
        name : '',
        description : ''
    });
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        API.get('prompts/share/get/' + id)
        .then((e) => {
            setData(e.data);
        })
        .catch((err) => {
            navigate("/404");
        })
        .finally((e) => {
            setIsLoading(false);
        })

    }, []);

    const [isBtnLoading, setIsBtnLoading] = useState(false);

    const addSharedPrompt = () => {

        setIsBtnLoading(true);

        API.get('prompts/share/add/' + id)
        .then((e) => {
            navigate("/prompts");
        })
        .catch((err) => {
            navigate("/prompts");
        })
        .finally((e) => {
            setIsBtnLoading(true);
        });
    }

    const redirectToRegister = () => {
        global._sharedPromptId = id;
        navigate("/register");
    }


    return (

        isLoading ?

        <BigProgress />


        :


        <>

            <NavBarAuth />


            <div className="app-container px-2 flex justify-center flex-col items-center">

                <div className="border border-slate-300 px-10 py-20 rounded-lg lg:w-1/2">

                    <H2>{data.authorName} has shared a prompt with you.</H2>


                    {
                        isLoggedIn ?

                            <>
                                <div className="mb-6">
                                    <label className="font-medium">Name : </label>
                                    <span>{data.name}</span>
                                </div>

                                <div className="mb-6">
                                    <label className="font-medium">Description : </label>
                                    <span className="whitespace-pre-line">{data.description}</span>
                                </div>

                                <div className="flex mt-5">
                                    <Button onClick={addSharedPrompt} isLoading={isBtnLoading}>Add In Your Prompt Book</Button>
                                    <Button className={'bg-gray-500 ml-3 hover:bg-gray-600'}>Back To Home</Button>
                                </div>
                            </>

                            :

                            <>

                                <div className="mb-6">
                                    <label className="font-medium">Name : </label>
                                    <span>{data.name}</span>
                                </div>

                                <div className="mb-6">
                                    <label className="font-medium">Description : </label>
                                    <span className="whitespace-pre-line">{data.description}</span>
                                </div>

                                <span className="mt-5 font-medium">To view and run prompts, you need an account.</span>


                                <div className="flex mt-5">
                                    <Button onClick={redirectToRegister}>Sign Up</Button>
                                </div>



                            </>
                    }

                    {/* <span>The page you are trying to access could not be found. Go back to <Link className="font-bold" to={'/'}>Home</Link></span> */}

                </div>

            </div>

        </>

    )

}

export default SharePrompt;