const Constants = {

    //without end slash /
    //apiUrl: "https://api.andbam.agency",

    apiUrl : "https://api.shovls.app",

    //apiUrl: "http://localhost:8002",

    appName: "Shovls",

    homeUrl : "/dashboard",

   // mainUrl : "http://localhost:3000"

   mainUrl : "https://shovls.app"
}

export default Constants;