import logo from './logo.svg';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import LoadingBar from 'react-top-loading-bar';

import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import Constants from './Support/Constants';
import Protected from './Support/Protected';
import { BigProgress } from './Components/Basics';


import Login from './Pages/Login';
import Register from './Pages/Register';
import Profile from './Pages/Profile';
import Logout from './Pages/Logout';
import NotFound from './Pages/NotFound';
import AddPrompt from './Pages/AddPrompt';
import Prompts from './Pages/Prompts';
import UpdatePrompt from './Pages/UpdatePrompt';
import Dashboard from './Pages/Dashboard';
import SharePrompt from './Pages/SharePrompt';


function App() {

  const location = useLocation();

  const loadingRef = useRef(null);

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {

    axios.get(Constants.apiUrl + `/login-checker`, {
      withCredentials: true
    })
      .then((e) => {

        global.apiKey = e.data.apiKey;

        if (e.data.isLoggedIn === true) {
          //window.location.replace("/projects");
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }

      })
      .catch((err) => {

        setIsLoggedIn(false);

      });

  }, []);

  const updateLoggedIn = () => setIsLoggedIn(false);


  useEffect(() => {

    loadingRef.current.continuousStart();

    setTimeout(() => {
      loadingRef.current.complete();
    }, 300);


  }, [location]);

  return (

    isLoggedIn == null

      ?

      <>

        <LoadingBar color='#1956db' height={4} ref={loadingRef} />
        <div className="h-screen">
          <BigProgress />
        </div>

      </>


      :

      <>

        <LoadingBar color='#1956db' height={4} ref={loadingRef} />

        <Routes>

          <Route path='/' element={<Login isLoggedIn={isLoggedIn} />} />

          <Route path='login' element={<Login isLoggedIn={isLoggedIn} />} />

          <Route path='register' element={<Register isLoggedIn={isLoggedIn} />} />

          <Route exact path="shared/:id"
            element={
              <SharePrompt isLoggedIn={isLoggedIn} />
            }
          />

          <Route exact path="dashboard"
            element={
              <Protected isLoggedIn={isLoggedIn}><Dashboard /></Protected>
            }
          />

          <Route exact path="prompts/add"
            element={
              <Protected isLoggedIn={isLoggedIn}><AddPrompt /></Protected>
            }
          />

          <Route exact path="prompts/update/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}><UpdatePrompt /></Protected>
            }
          />


          <Route exact path="prompts"
            element={
              <Protected isLoggedIn={isLoggedIn}><Prompts /></Protected>
            }
          />




          <Route exact path="profile"
            element={
              <Protected isLoggedIn={isLoggedIn}><Profile /></Protected>
            }
          />

          <Route exact path="logout"
            element={
              <Protected isLoggedIn={isLoggedIn}><Logout updateLoggedIn={updateLoggedIn} /></Protected>
            }
          />

          <Route path='*' element={<NotFound />} />


        </Routes>

      </>

  );
}

export default App;
