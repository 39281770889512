
import { RiUserLine, RiDashboard2Line, RiAddBoxLine, RiParkingBoxLine, RiLock2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Sidebar = ({ whichActive }) => {


    const [active, setActive] = useState({
        dashboard: "",
        profile: "",
        prompts: "",
        addPrompt : ""
    });

    useEffect(() => {

        let tempActive = { ...active };

        tempActive[whichActive] = "bg-gray-300";

        setActive(tempActive);

    }, [whichActive]);

    return (

        <aside id="default-sidebar" className="z-40 w-56 xl:w-auto h-screen transition-transform -translate-x-full translate-x-0" aria-label="Sidebar">
            <div className="h-full px-3 py-4 overflow-y-auto bg-slate-100 dark:bg-gray-800">

                <div className="mb-3 ml-3 mt-3">
                    <span className="font-bold">Menu</span>
                </div>

                <ul className="space-y-2 font-medium">
                    <li>
                        <Link to="/dashboard" className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 ${active.dashboard}`}>
                            <RiDashboard2Line size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/prompts/add" className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 ${active.addPrompt}`}>
                            <RiAddBoxLine size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Add Prompt</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/prompts" className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 ${active.prompts}`}>
                            <RiParkingBoxLine size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Prompts</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile" className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 ${active.profile}`}>
                            <RiUserLine size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Profile</span>
                        </Link>
                    </li>

                    <li>
                        <Link to="/logout" className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700`}>
                            <RiLock2Line size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Logout</span>
                        </Link>
                    </li>

                </ul>
            </div>
        </aside>


    );

}

export default Sidebar;