import NavBarAuth from "../Components/NavBarAuth";
import { useRef, useState } from "react";
import { isEmail, isEmpty } from "../Support/FormValidator";
import { Button, Input, Link, H2 } from "../Components/Basics";
import API from "../Support/API";
import { useNavigate, Navigate } from "react-router-dom";


const Register = ({isLoggedIn}) => {

    const formRef = useRef();
    const navigate = useNavigate();

    const [errMsg, setErrMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onRegister = () => {

        setErrMsg("");

        if (isEmpty(["name", "email", "password", "cpassword"], formRef.current)) {
            setErrMsg("Please fill all the fields.");
            return;
        }

        const { name, email, password, cpassword } = formRef.current;

        if (!isEmail(email.value)) {
            setErrMsg("Not a valid email");
            return;
        }

        if (password.value.length < 8) {
            setErrMsg("Password should be atleast 8 characeters long.");
            return;
        }

        if (password.value !== cpassword.value) {
            setErrMsg("Confirm Password does not match");
            return;
        }

        if (name.value.length < 3) {
            setErrMsg("Name should be 3 characters long.");
            return;
        }

        setIsLoading(true);

        API.post("register", {
            name: name.value,
            password: password.value,
            cpassword: cpassword.value,
            email: email.value
        })
            .then((e) => {

                navigate("/login", { replace: true, state: { msg: "Your account is created, sign in now." } });
            })
            .catch((err) => {

                // console.log(err)

                setErrMsg(err.message);
                setIsLoading(false);

            });

    }



    return (

        isLoggedIn ?

         <Navigate to="/chatbots" />

        :


        <>
            <NavBarAuth />


            <div className="app-container px-3 flex justify-center flex-col items-center">

                <div className="border border-slate-300 px-10 py-20 rounded-lg w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/4">

                    <H2>
                        Sign Up
                    </H2>

                    {
                        errMsg && <div className="mb-4"><span className="text-red-600">{errMsg}</span></div>
                    }

                    <form ref={formRef}>

                        <Input

                            type="text"
                            label="Your Name"
                            name="name"

                        />

                        <Input

                            type="email"
                            label="Your Email"
                            name="email"

                        />

                        <Input

                            type="password"
                            label="Your Password"
                            name="password"

                        />

                        <Input

                            type="password"
                            label="Confirm Your Password"
                            name="cpassword"

                        />

                        <div className="my-5">
                            <span className="mr-1">Already have an account ?</span>
                            <Link to={"/login"}>
                                Sign In
                            </Link>
                        </div>

                        <Button isLoading={isLoading} onClick={onRegister} className="w-full">
                            Sign Up
                        </Button>



                    </form>

                </div>


            </div>
        </>
    );
}

export default Register;