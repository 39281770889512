import { useEffect, useRef, useState } from "react";
import { Modal } from "flowbite";
import { BiCopy } from "react-icons/bi";
import Constants from "../../Support/Constants";
import { Button, Progress } from "../Basics";
import API from "../../Support/API";

const ShareModal = ({ show, promptId }) => {

    const [modal, setModal] = useState();
    const modalContainer = useRef();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        // set the modal menu element
        const targetEl = document.getElementById('notice-modal');

        // options with default values
        const options = {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'fixed inset-0 z-40 backdrop-blur-sm bg-opacity-50 bg-black dark:bg-gray-900 dark:bg-opacity-80',
            closable: true,
        };

        let m = new Modal(targetEl, options);
        setModal(m);


    }, []);

    useEffect(() => {

        if (show === 0) {
            return;
        }

        modalContainer.current.classList.add("modal-open");
        modalContainer.current.classList.remove("modal-close");

        modal.show();

    }, [show]);

    const closeModal = () => {

        modalContainer.current.classList.remove("modal-open");
        modalContainer.current.classList.add("modal-close");

        let to = setTimeout(() => {
            modal.hide();
        }, 200);
    }

    const formRef = useRef();
    const [isBtnLoading, setIsBtnLoading] = useState(false);

    useEffect(() => {

        setIsLoading(true);
        const { description, allowSharing } = formRef.current;

        API.get('prompts/share/details/' + promptId)
            .then((e) => {
                description.value = e.data.description;
                allowSharing.checked = e.data.allowSharing === 1 ? true : false;
                setIsLoading(false);
            })
            .catch((err) => {

            });


    }, [promptId]);

    const copyLink = () => {
        navigator.clipboard.writeText(Constants.mainUrl + '/shared/' + promptId);
    }


    const onSave = () => {

        const { description, allowSharing } = formRef.current;

        setIsBtnLoading(true);

        API.post('prompts/share/update/' + promptId, {
            description: description.value,
            allowSharing: allowSharing.checked
        })
            .then((e) => {
                setIsBtnLoading(false);
            })
            .catch((err) => {
                setIsBtnLoading(false);
            });
    }


    return (

        <div ref={modalContainer} id="notice-modal" tabindex="-1" aria-hidden="true" className="modal-open fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative w-full max-w-2xl max-h-full">

                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Share
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                            <svg onClick={closeModal} aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-6 space-y-6">

                        {
                            isLoading &&

                            <Progress />
                        }

                        <form ref={formRef} style={{ display: isLoading === true ? 'none' : 'block' }}>

                            <div className="flex mb-6 justify-between items-center">
                                <p className="text-base leading-relaxed text-gray-600 dark:text-gray-400">
                                    Allow Sharing
                                </p>

                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input name="allowSharing" type="checkbox" value="" className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label>
                            </div>

                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                                <textarea name="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Enter about the prompt..."></textarea>
                            </div>

                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Share Link</label>
                                <div className="flex items-center">
                                    <input value={Constants.mainUrl + '/shared/' + promptId} type="text" disabled={true} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" />
                                    <div onClick={copyLink} className="ml-2 cursor-pointer flex items-center justify-center p-2 rounded-lg bg-blue-600 hover:bg-blue-800"><BiCopy size={20} className="text-white" /></div>
                                </div>
                            </div>


                        </form>


                    </div>


                    {
                        isLoading === false &&
                        <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <Button isLoading={isBtnLoading} onClick={onSave}>Save</Button>
                            <button onClick={closeModal} data-modal-hide="delete-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 rounded border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">Close</button>
                        </div>
                    }

                </div>
            </div>
        </div>
    )

}

export default ShareModal;