import { useEffect, useRef, useState } from "react";
import { Modal } from "flowbite";
import { ErrorMsg, Progress } from "../Basics";
import API from "../../Support/API";

const CopyModal = ({ show, id, copyFunc }) => {

    const [modal, setModal] = useState();
    const modalContainer = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {

        // set the modal menu element
        const targetEl = document.getElementById('copy-modal');

        // options with default values
        const options = {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'fixed inset-0 z-40 backdrop-blur-sm bg-opacity-50 bg-black dark:bg-gray-900 dark:bg-opacity-80',
            closable: true,
        };

        let m = new Modal(targetEl, options);
        setModal(m);


    }, []);

    useEffect(() => {

        if (show === 0) {
            return;
        }

        modalContainer.current.classList.add("modal-open");
        modalContainer.current.classList.remove("modal-close");

        modal.show();

    }, [show]);

    const closeModal = () => {

        modalContainer.current.classList.remove("modal-open");
        modalContainer.current.classList.add("modal-close");
        setErrMsg("");

        let to = setTimeout(() => {
            modal.hide();
        }, 200);
    }

    const onCopy = () => {

        setIsLoading(true);

        API.get("copy-prompt/" + id)
        .then((e) => {
            modal.hide();
            copyFunc(e.id);
        })
        .catch((err) => {
            setErrMsg(err.message);
        })
        .finally(() => {
            setIsLoading(false);
        });

    }


    return (

        <div ref={modalContainer} id="copy-modal" tabindex="-1" className="modal-open fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative w-full max-w-lg max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button onClick={closeModal} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="delete-modal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 text-center">
                        <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to copy this prompt?</h3>

                        {
                            isLoading ?

                                <div className="my-2">
                                    <Progress />
                                </div>

                                :

                                <>

                                    <ErrorMsg errMsg={errMsg} />

                                    <button onClick={onCopy} data-modal-hide="delete-modal" type="button" className="text-white bg-blue-600 hover:bg-blue-800 font-medium rounded text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                        Yes, I'm sure
                                    </button>
                                    <button onClick={closeModal} data-modal-hide="delete-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 rounded border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">No, cancel</button>

                                </>
                        }
                    </div>
                </div>
            </div>
        </div>

    )

}

export default CopyModal;