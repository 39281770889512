import { Link } from "react-router-dom";
import Constants from "../Support/Constants";
import logo from "../Images/logo.png";


export default function NavBarAuth() {

    return (


        <nav className="mb-96 bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">

            <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">

                <Link to="/" className="flex items-center">
                    <img src={logo} width={47} className="mr-2 shadow-lg rounded-full" />
                    <span className="self-center lg:text-2xl sm:text-xl font-semibold whitespace-nowrap dark:text-white">{Constants.appName}</span>
                </Link>



            </div>
        </nav>


    );
}