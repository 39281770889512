import Main from "../Components/Main";
import { Input, H3, H5, Button, BigProgress, TextArea, Select, Card, SelectWithValue } from "../Components/Basics";
import { useRef, useState } from "react";
import { validator } from "../Support/FormValidator";
import API from "../Support/API";
import { Link } from "react-router-dom";
import Constants from "../Support/Constants";
import { RiStopCircleLine } from "react-icons/ri";
import { marked } from 'marked';
import { useSnackbar } from "react-simple-snackbar";
import { MdContentCopy } from "react-icons/md";
import OpenAI from 'openai';


const AddPrompt = () => {

    const formRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [okMsg, setOkMsg] = useState("");

    const promptId = useRef(null);
    const output = useRef("");
    const markedText = useRef("");
    const eventSource = useRef(null);
    const outputDiv = useRef(null);

    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isRunLoading, setIsRunLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [tempValue, setTempValue] = useState(7); // Initial value
    const [isRunning, setIsRunning] = useState(false);


    const onSubmit = (btnName) => {

        setErrMsg((e) => e !== "" && "");

        const { name,
            context,
            role,
            task,
            goal,
            steps,
            constraints,
            outputFormat,
            tags
        } = formRef.current;

        const validate = validator([
            "name", "outputFormat", "role"], formRef.current);

        if (validate.isEmpty) {
            setErrMsg(validate.errorMsg);
            return;
        }

        const formData = {
            name: name.value,
            context: context.value,
            role: role.value,
            task: task.value,
            goal: goal.value,
            steps: steps.value,
            constraints: constraints.value,
            outputFormat: outputFormat.value,
            tags: tags.value,
            temperature: tempValue,
            id: promptId.current
        };

        setBtnLoading(btnName, true);

        API.post("add-update-prompt", formData)
            .then((e) => {

                //upading prompt id
                promptId.current = e.id;

                if (btnName === 'run') {
                    callEventStream();
                }

                if (btnName === 'submit') {
                    setOkMsg("Saved successfully");
                }


            })
            .catch((err) => {
                setErrMsg(err.message);
            })
            .finally(() => {

                //only make loading false if its submit
                if (btnName === "submit") {
                    setBtnLoading(btnName, false);
                }

            });
    }

    const setBtnLoading = (name, cond) => {

        if (name === "submit") {
            setIsBtnLoading(cond);
        }
        else if (name === "run") {
            setIsRunLoading(cond);
        }


    }

    let callEventStream = async () => {

        global.stop = false;

        output.current.innerHTML = "";
        markedText.current = "";
        scrollToOutput();

        //getting model
        const { model } = formRef.current;

        let startTime = 0;
        let endTime = 0;

        let messages = [];
        let prompt = copyPrompt(true);

        messages.push({
            role: 'system',
            content: "You are ChatGPT, a large language model trained by OpenAI. Answer in as much detail as possible."
        });

        messages.push({
            role: 'user',
            content: prompt
        });

        setIsRunning(true);
        startTime = performance.now();

        const openai = new OpenAI({
            apiKey: global.apiKey,
            dangerouslyAllowBrowser: true
        });


        let stream;

        try {

            stream = await openai.chat.completions.create({
                model: model.value,
                messages: messages,
                stream: true,
                temperature: tempValue / 10
            });
        }
        catch (err) {

            output.current.innerHTML = err.message;

            setBtnLoading('run', false);
            setIsRunning(false);
            return;

        }

        for await (const part of stream) {

            if (global.stop === true) {
                return;
            }

            let content = part.choices[0]?.delta?.content || '';

            markedText.current += content;

            // If next line then use markdown
            if (content.includes('\n')) {
                const parsedMarkdown = marked.parse(markedText.current);
                output.current.innerHTML = parsedMarkdown;
            } else {
                output.current.innerHTML += content;
            }

            output.current.scrollTop = output.current.scrollHeight;
        }


        //end
        setBtnLoading('run', false);
        setIsRunning(false);

        endTime = performance.now();
        const totalTimeTaken = calculateTotalTime(startTime, endTime);
        updateOutput(output.current.innerHTML, totalTimeTaken);


    }



    const stopRunning = () => {

        global.stop = true;
        setIsRunning(false);
        setBtnLoading('run', false);
    }

    const scrollToOutput = () => {

        const elementPosition = output.current.getBoundingClientRect();
        const currentScrollY = window.scrollY || window.pageYOffset;
        const targetScrollY = currentScrollY + elementPosition.top; // Adjust as needed

        window.scrollTo({
            top: targetScrollY,
            behavior: 'smooth'
        });
    }

    const updateOutput = (outputText, timeTaken) => {

        API.post("update-output", {
            output: outputText,
            seconds: timeTaken,
            id: promptId.current
        }).catch((err) => {

        })
    }

    const calculateTotalTime = (startTime, endTime) => {

        // Calculate the time difference in milliseconds
        const timeDiffMilliseconds = endTime - startTime;

        // Convert time difference to seconds
        const totalTimeSeconds = timeDiffMilliseconds / 1000;

        return totalTimeSeconds.toFixed(2);
    }

    const [openSnackbar, closeSnackbar] = useSnackbar();

    const copyOutput = () => {
        navigator.clipboard.writeText(output.current.innerText);
        openSnackbar("Copied to clipboard", 2000);
    }

    const copyPrompt = (returnOutput = false) => {

        const inputs = ['role', 'task', 'goal', 'steps', 'constraints'];
        const inputValues = {}
        let prompt = "";

        const inputElements = formRef.current.querySelectorAll('textarea');
        const { outputFormat } = formRef.current;

        inputElements.forEach(inputElement => {
            inputValues[inputElement.name] = inputElement.value;
        });

        for (const i of inputs) {

            if (inputValues[i] === undefined) {
                continue;
            }

            prompt += inputValues[i] + "\n\n";
        }

        prompt = prompt.trim();

        if (outputFormat.value === "with-markdown") {
            prompt += "\n\n## Please use markdowns (headings, bold, list, etc) in your response.";
        }

        if (returnOutput === false) {

            navigator.clipboard.writeText(prompt);
            openSnackbar("Copied to clipboard", 2000);
        }

        return prompt;
    }

    return (

        <Main active="addPrompt">

            <H3 className="ml-3 mb-5 mt-3">
                New Prompt
            </H3>

            <div className="m-3 grid lg:grid-cols-5 grid-col-1 gap-8">


                <form ref={formRef} className="w-full lg:col-span-2 col-span-1">


                    <div>

                        <div className="flex justify-between">
                            <H5>Prompt Name</H5>
                            <MdContentCopy onClick={copyPrompt} className="text-gray-500 dark:text-gray-400" size={22} />
                        </div>

                        <Input

                            type="text"
                            label="Name of the prompt"
                            name="name"
                            important={true}

                        />
                    </div>

                    <div>

                        <H5>Prompt Context</H5>
                        <TextArea

                            type="text"
                            label="What is the prompt about"
                            name="context"
                            rows={5}

                        />
                    </div>

                    <hr className="my-8" />

                    <div>

                        <H5>Prompt Role</H5>
                        <TextArea

                            type="text"
                            label="Describe the prompt persona or expertice you would like the AI to perform"
                            name="role"
                            rows={5}
                            important={true}

                        />
                    </div>

                    <div>

                        <H5>Task</H5>
                        <TextArea

                            type="text"
                            label="Describe the task you would like to accomplish"
                            name="task"
                            rows={7}

                        />
                    </div>

                    <div>

                        <H5>Results</H5>
                        <TextArea

                            type="text"
                            label="Describe the goal of the prompt"
                            name="goal"
                            rows={5}

                        />
                    </div>

                    <div>

                        <H5>Steps</H5>
                        <TextArea

                            type="text"
                            label="Describe the steps the AI should take to complete task"
                            name="steps"
                            rows={5}

                        />
                    </div>

                    <div>

                        <H5>Prompt Constraints</H5>
                        <TextArea

                            type="text"
                            label="What are the rules the AI should abide by ?"
                            name="constraints"
                            rows={5}

                        />
                    </div>

                    <hr className="my-8" />


                    <div>

                        <H5>Tags</H5>
                        <Input

                            type="text"
                            label="Write tags each separated by comma ',' for better search (Optional))"
                            name="tags"
                            placeholder={"article, marketing"}

                        />
                    </div>

                    <div>

                        <H5>Output Format</H5>
                        <SelectWithValue

                            label="How do you want to see the data presented"
                            name="outputFormat"
                            options={[
                                {
                                    title: "With Markdown",
                                    value: "with-markdown"
                                },
                                {
                                    title: "Without Markdown",
                                    value: "without-markdown"
                                }]}

                        />
                    </div>


                    <div>

                        <H5>Model</H5>
                        <SelectWithValue

                            label="Select GPT Model"
                            name="model"
                            options={[
                                {
                                    title: "GPT-3.5 (4K)",
                                    value: "gpt-3.5-turbo"
                                },
                                {
                                    title: "GPT-3.5 (16K)",
                                    value: "gpt-3.5-turbo-16k"
                                },
                                {
                                    title: "GPT-4 (8K)",
                                    value: "gpt-4"
                                },
                                {
                                    title: "GPT-4 (32K)",
                                    value: "gpt-4-32K"
                                },

                            ]}

                        />
                    </div>

                    <div className="my-5">

                        <H5>Creative Level</H5>

                        <div className="flex justify-between">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Model Temperature <span className="text-red-500">*</span></label>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{tempValue / 10}</label>
                        </div>

                        <input type="range" min={0} max={20} value={tempValue} onChange={(event) => setTempValue(event.target.value)} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />

                    </div>



                    {
                        errMsg && <div className="my-4"><span className="text-red-600">{errMsg}</span></div>
                    }

                    {
                        okMsg && <div className="my-4"><span className="text-green-500">{okMsg}</span></div>
                    }


                    <div className="flex w-full items-center flex-wrap justify-between">
                        <Link to={'/prompts'} className="w-full sm:w-auto">
                            <Button className={"mt-2 w-full sm:w-auto"}>
                                Back To Prompts
                            </Button>
                        </Link>
                        <div className="w-full sm:w-auto">
                            <Button onClick={() => onSubmit('run')} isLoading={isRunLoading} className={"mt-2 sm:w-28 w-full bg-red-700 hover:bg-red-800"}>
                                Run
                            </Button>
                            <Button onClick={() => onSubmit('submit')} isLoading={isBtnLoading} className={"sm:ml-2 sm:w-28 w-full mt-2 bg-green-500 hover:bg-green-600"}>
                                Save
                            </Button>
                        </div>
                    </div>

                </form>



                <div className="mt-10 lg:mt-0 lg:w-full lg:col-span-3 col-span-1 flex flex-col w-full">
                    <H5>Output</H5>
                    <div ref={output} className="dark:text-white prose prose-slate max-w-none border border-1 border-slate-200 dark:border-slate-500 p-3 overflow-y-auto hide-scrollbar w-full overflow-x-hidden" style={{ height: "1920px" }}>

                    </div>
                    <div className="flex justify-between">
                        <div></div>
                        <MdContentCopy onClick={copyOutput} className="my-3 text-gray-500 dark:text-gray-400" size={22} />
                    </div>
                </div>

                {
                    isRunning &&

                    <Button onClick={stopRunning} className={"flex justify-center items-center mt-2 sm:w-28 w-full bg-red-700 hover:bg-red-800 fixed bottom-14 inset-x-1/2 shadow-lg"}>
                        <div>
                            <RiStopCircleLine size={20} />
                        </div>
                        <div className="ml-2">
                            Stop
                        </div>
                    </Button>
                }


            </div>


        </Main>

    );


}

export default AddPrompt;