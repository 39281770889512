import { RiLock2Line, RiMenu2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import Constants from "../Support/Constants";
import logo from "../Images/logo.png";

export default function NavBar() {

    const showMenu = () => {

        console.log("ff")

        const sideMenu = document.getElementById("sideMenu");

        const addClasses = ["flex", "z-10", "pt-14"];

        if(sideMenu.classList.contains("hidden")){

            sideMenu.classList.remove("hidden");
            addClasses.forEach((e) => sideMenu.classList.add(e));

        }
        else{
            
            addClasses.forEach((e) => sideMenu.classList.remove(e));
            sideMenu.classList.add("hidden");
            
        }
    }

    return (


        <nav className="w-full bg-white dark:bg-gray-900 z-20 border-b border-gray-200 dark:border-gray-600">

            <div className="flex flex-wrap items-center w-full justify-between p-4">

                <div className="flex items-center">
                    <RiMenu2Line onClick={showMenu} size={25} className="mr-3 flex lg:flex xl:hidden" />
                    <Link to={Constants.homeUrl} className="flex items-center">
                        <img src={logo} width={47} className="mr-2 shadow-lg rounded-full" />
                        <span className="self-center lg:text-2xl sm:text-xl font-semibold whitespace-nowrap dark:text-white">{Constants.appName}</span>
                    </Link>
                </div>

                <div className="flex md:order-2">
                    <Link to={"/logout"}>
                        <RiLock2Line className="text-xl lg:text-2xl" color="red" />
                    </Link>
                </div>

            </div>
        </nav>


    );
}