const isEmpty = (arr, form) => {

    for(const key of arr){
        
        if(form[key].value == ""){
            return true;
        }
    }

    return false;
}

const validator = (arr, form) => {

    const obj = {};

    for(const key of arr){
        
        if(form[key].value == ""){
            obj.isEmpty = true;
            obj.errorMsg = `${key} field is empty !`;
            return obj;
        }
    }

    obj.isEmpty = false;
    return obj;
}

const isEmail = (email) => {

    if(email.match(/^\S+@\S+\.\S+$/)){
        return true;
    }else{
        return false;
    }
}


const isSpecialCharsOk = (text) => {

    ///if text does not contain special chars except _ then return true
    if(text.match(/^[a-zA-Z0-9_]+$/)){
        return true;
    }
    else{
        return false;
    }
}

export {isEmpty, isEmail, isSpecialCharsOk, validator};