import { Link as ReactLink } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { MdContentCopy } from "react-icons/md";

const Button = ({ className, children, onClick, isLoading = false }) => {

   if (!isLoading) {

      return (

         <button onClick={onClick} type="button" className={`text-white bg-blue-700 hover:bg-blue-800 font-medium rounded text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 ${className}`}>{children}</button>

      );

   }
   else {

      return (

         <button type="button" style={{ paddingTop: "9px", paddingBottom: "9px" }} className={`text-white bg-blue-700 hover:bg-blue-800 font-medium rounded text-sm px-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 ${className}`}>
            <div role="status">
               <svg aria-hidden="true" class="inline w-5 h-6 mr-2 text-white animate-spin dark:text-white fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
               </svg>
               <span className="sr-only">Loading...</span>
            </div>
         </button>
      );

   }



}

const SmallButton = ({ className, children, onClick }) => {

   return (

      <button onClick={onClick} type="button" className={`px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 ${className}`}>{children}</button>

   )

}

const Input = ({ label, type, placeholder, name, className, value, important, ...props }) => {

   return (

      <div className="mb-6">
         <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}{important && <span className="text-red-500 ml-2">*</span>}</label>
         <input defaultValue={value} {...props} placeholder={placeholder} name={name} type={type} className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${className}`} />
      </div>

   );
}

const TextArea = ({ label, placeholder, name, className, value = "", rows = 3, important, ...props }) => {
   return (

      <div className="mb-6">

         <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}{important && <span className="text-red-500 ml-2">*</span>}</label>
         <textarea name={name} rows={rows} className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${className}`} placeholder={placeholder} defaultValue={value}></textarea>

      </div>

   );
}

const Select = ({ label, options, className, selectedValue = "", name, ...props }) => {
   return (

      <div className="mb-6">

         <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
         <select defaultValue={selectedValue} name={name} className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${className}`}>
            {
               options.map((e) => <option value={e}>{e}</option>)
            }
         </select>

      </div>

   )
}

const SelectWithValue = ({ label, options, className, name, selectedValue = "", title, value, ...props }) => {
   return (

      <div className="mb-6">

         <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
         <select defaultValue={selectedValue} name={name} className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${className}`}>
            {
               options.map((e) => <option value={e.value}>{e.title}</option>)
            }
         </select>

      </div>

   )
}

const MultipleCheckboxes = ({ label, items, name, selectedValues = "", ...props }) => {
   return (

      <div className="mb-6">

         <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
         <div className="flex flex-col justify-center mb-4">

            {
               items.map((e) => {
                  return (

                     selectedValues.includes(e) ?

                        <div>
                           <input defaultChecked name={name} type="checkbox" value={e} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600" />
                           <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{e}</label>
                        </div>

                        :

                        <div>
                           <input name={name} type="checkbox" value={e} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600" />
                           <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{e}</label>
                        </div>
                  )
               })
            }

         </div>

      </div>

   )
}

const Link = ({ children, className, to }) => {

   return (

      <ReactLink className={`text-blue-600 dark:text-blue-300 ${className}`} to={to}>
         {children}
      </ReactLink>

   );
}

const H2 = ({ children, className }) => {

   return (

      <h2 className={`mb-7 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-white ${className}`}>{children}</h2>

   );
}

const H3 = ({ children, className }) => {

   return (

      <h3 className={`text-xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-3xl dark:text-white ${className}`}>{children}</h3>

   );
}

const H4 = ({ children, className }) => {

   return (

      <h4 className={`text-lg font-bold dark:text-white mb-2 tracking-tight ${className}`}>{children}</h4>

   );
}

const H5 = ({ children, className }) => {

   return (

      <h4 className={`text-base font-bold dark:text-white mb-2 tracking-tight ${className}`}>{children}</h4>

   );
}




const BigProgress = () => {

   return (

      <div className="flex items-center justify-center w-full h-screen">
         <div role="status">
            <svg aria-hidden="true" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
               <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
            <span className="sr-only">Loading...</span>
         </div>
      </div>

   );

}

const APIProgress = () => {

   return (

      <div className="c-height w-full items-center flex justify-center flex-col">

         <div role="status">
            <svg aria-hidden="true" className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
               <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
            <span className="sr-only">Loading...</span>
         </div>

      </div>
   )
}

const Progress = () => {

   return (
      <div className="flex items-center justify-center w-full">
         <div role="status">
            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
               <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
            <span className="sr-only">Loading...</span>
         </div>
      </div>
   )
}

const ErrorMsg = ({ errMsg }) => {

   return (

      errMsg ? <div className="my-3"><span className="text-red-600 text-sm">{errMsg}</span></div> : <></>

   );



}

const Card = ({ text, className }) => {

   const [openSnackbar, closeSnackbar] = useSnackbar();

   const copyText = () => {
      navigator.clipboard.writeText(text);
      openSnackbar("Copied to clipboard", 2000);
   }

   return (


      <div className={`flex justify-between flex-col min-w-full p-6 bg-slate-50 rounded shadow dark:bg-gray-800 dark:border-gray-700 ${className}`}>

         <p className="mb-3 font-normal text-black dark:text-gray-400 whitespace-pre-line">{text}</p>

         <div className="my-3" onClick={copyText}>
            <MdContentCopy size={22} />
         </div>

      </div>


   );
}

const LinearProgress = ({ percentage }) => {

   return (
      <>
         <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
           {
            percentage >= 70 ?

            <div className="bg-green-600 h-1.5 rounded-full dark:bg-green-500" style={{ width: `${percentage}%` }}></div>

            :

            percentage >= 40 ?

            <div className="bg-yellow-400 h-1.5 rounded-full dark:bg-yellow-300" style={{ width: `${percentage}%` }}></div>

            :

            <div className="bg-red-600 h-1.5 rounded-full dark:bg-red-500" style={{ width: `${percentage}%` }}></div>
          

           }
         </div>
      </>
   )
}

const DashboardCard = ({ title, value, icon }) => {

   return (

      <div className="w-full lg:w-80 sm:m-8 mt-4 mb-0 sm:ml-0 block flex flex-wrap items-center p-6 bg-white rounded-lg border-slate-200 border dark:bg-gray-800 dark:border-gray-700">

         <div className="mr-5">
            {icon}
         </div>
         <div>
            <p className="font-normal text-gray-700 dark:text-gray-400">{title}</p>
            <h5 className="mb-2 text-2xl tracking-wide font-semibold tracking-tight text-gray-900 dark:text-white">{value}</h5>
         </div>

      </div>

   )
}

const DangerAlert = ({children}) => {

   return (
      <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
         <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
         </svg>
         <span className="sr-only">Info</span>
         <div>
            {children}
         </div>
      </div>
   )
}

export {
   Button, Input, Link, SmallButton, H2, H3, BigProgress, APIProgress,

   Progress, ErrorMsg, TextArea, Select, Card, SelectWithValue, MultipleCheckboxes, LinearProgress,

   H4, H5, DashboardCard, DangerAlert

};