import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { BigProgress } from "../Components/Basics";

const Protected = ({isLoggedIn, children}) => {

    const [isAuth, setIsAuth] = useState(isLoggedIn);

    useEffect(() => {

       setIsAuth(isLoggedIn);
       //console.log(isLoggedIn);
        

    }, [isLoggedIn]);

    return(

        isAuth === true ?
        

        children

        :

        <Navigate to="/login" />

    )

    



}

export default Protected;