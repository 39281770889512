import { useEffect, useRef, useState } from "react";
import API from "../Support/API";
import { APIProgress, LinearProgress } from "../Components/Basics";
import { TbMoodEmpty } from "react-icons/tb";
import Main from "../Components/Main";
import { RiDeleteBin7Fill, RiEdit2Fill, RiParkingBoxLine, RiSearchLine } from "react-icons/ri";
import dateFormatter from "dateformat";
import { H3, Link, SmallButton, Button } from "../Components/Basics";
import DeleteModal from "../Components/Modals/DeleteModal";
import { GreenBadge } from "../Components/Badges";
import {BiCopy} from "react-icons/bi";
import {AiOutlineDelete, AiOutlineEdit, AiOutlineShareAlt} from "react-icons/ai";
import CopyModal from "../Components/Modals/CopyModal";
import ShareModal from "../Components/Modals/ShareModal";

const Prompts = () => {

    const [pg, setPg] = useState(1);
    const allowPg = useRef(true);
    const hadSearch = useRef(false);
    const [data, setData] = useState(null);
    const [query, setQuery] = useState("");

    const pageNo = useRef(1);
    const isOnSearch = useRef(false);
    const searchInput = useRef('');

    useEffect(() => {
        getPromptsWithoutSearch(pageNo.current);
    }, []);

    const getPromptsWithoutSearch = (page) => {

        if (!allowPg.current) return;

        allowPg.current = false;

        let moreData = true;

        API.get(`prompts/${page}`)
        .then((e) => {

            if (e.data.length === 0) {

              setData((d) => {
                if(d === null) return [];
                else {
                    moreData = false;
                    return d;
                };
              });

            }
            else{

                const prompts = e.data;

                setData((d) => {
                    if(d === null) return prompts
                    else return [...d,...prompts];
                });

            }
        })
        .catch((err) => {
            setData(false);
            console.log(err);
        })
        .finally(() => {

            //if no more data is left
            if(moreData){
                allowPg.current = true;
            }
            else{
                allowPg.current = false;
            }
        })

    }

    const getPromptsWithSearch = (page,query) => {

        if (!allowPg.current) return;

        allowPg.current = false;

        let moreData = true;

        API.get(`prompts/${page}?query=${query}`)
        .then((e) => {

            if (e.data.length === 0) {

              setData((d) => {
                if(d === null) return [];
                else {
                    moreData = false;
                    return d;
                };
              });

            }
            else{

                const prompts = e.data;

                setData((d) => {
                    if(d === null) return prompts
                    else return [...d,...prompts];
                });

            }
        })
        .catch((err) => {
            setData(false);
            console.log(err);
        })
        .finally(() => {

            //if no more data is left
            if(moreData){
                allowPg.current = true;
            }
            else{
                allowPg.current = false;
            }
        })

    }

    useEffect(() => {

        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            // Check if the user has scrolled to the bottom (with a little buffer)
            const buffer = 150; // Adjust this value as needed
            const isAtBottom = documentHeight - (windowHeight + scrollTop) <= buffer;


            if (isAtBottom) {

                if(allowPg.current){
                    pageNo.current += 1;
                }

                if(isOnSearch){
                    getPromptsWithSearch(pageNo.current, searchInput.current.value);
                }
                else{
                    getPromptsWithoutSearch(pageNo.current);
                }
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    const refresh = () => {
        setData(null);
        allowPg.current = true;
        pageNo.current = 1;
        getPromptsWithoutSearch(pageNo.current);
    }


    const formatNum = (number) => {

        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number.toLocaleString();
        }
    }

    const dateFormat = (date) => {

        const now = new Date(date);

        const newDate = dateFormatter(now, "mmmm dd, yyyy");

        return newDate;
    }

    const [showDltModal, setShowDltModal] = useState(0);
    const [dltId, setDltId] = useState("");

    const dltPrompt = (id) => {

        setDltId(id);
        setShowDltModal((showModal) => {
            return showModal + 1;
        });

    }

    const [showCopyModal, setShowCopyModal] = useState(0);
    const [copyId, setCopyId] = useState("");

    const copyPrompt = (id) => {

        setCopyId(id);
        setShowCopyModal((showModal) => {
            return showModal + 1;
        });

    }

    const [showShareModal, setShowShareModal] = useState(0);
    const [shareId, setShareId] = useState("");

    const sharePrompt = (id) => {

        setShareId(id);
        setShowShareModal((showModal) => {
            return showModal + 1;
        });

    }

    const removeElement = (id) => {

        setData((data) => {

            return data.filter((e) => e.id !== id);

        });
    };

    const copyElement = (id) => {

        refresh();
       
    };

    const onSearch = (e) => {

        //resetting
        if(e.target.value === ''){
            allowPg.current = true;
            isOnSearch.current = false;
            setData(null);
            getPromptsWithoutSearch(1);
            return;
        }
        

        if(e.target.value.length > 2){

            if(isOnSearch.current == false){
                allowPg.current = true;
                isOnSearch.current = true;
                pageNo.current = 1;
            }

            setData(null);
            getPromptsWithSearch(pageNo.current, e.target.value);
        }
      

        // setQuery(e.target.value);

    }

    return (

        <Main active="prompts">

            <DeleteModal id={dltId} show={showDltModal} removeFunc={removeElement} />

            <CopyModal id={copyId} show={showCopyModal} copyFunc={copyElement} />

            <ShareModal promptId={shareId} show={showShareModal} />

            <div className="flex items-center justify-between mb-5 mx-3 mt-3">

                <H3>
                    Prompts
                </H3>

                <Link to={"/prompts/add"}>
                    <Button>
                        Add New
                    </Button>
                </Link>


            </div>


            <div className="flex items-center my-5 px-2">
                <label for="simple-search" className="sr-only">Search</label>
                <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <RiSearchLine size={22} />
                    </div>
                    <input ref={searchInput} onInput={onSearch} type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search prompt with name, tags, context or role..." />
                </div>
             
            </div>



            {

                data === null ?

                    <APIProgress /> :

                    data === false ?

                        <div className="c-height w-full items-center flex justify-center flex-col">

                            <TbMoodEmpty className="my-3 text-blue-600" size={50} />

                            <p class="text-base text-gray-900 dark:text-white text-center">Something went wrong. please try later.</p>


                        </div>
                        :

                        data !== null && data.length === 0 ?

                            <div className="c-height w-full items-center flex justify-center flex-col">

                                <TbMoodEmpty className="my-3 text-blue-600" size={50} />

                                <p class="text-base text-gray-900 dark:text-white text-center">No Prompt found</p>


                            </div>

                            :

                            <>

                                <div className="relative overflow-x-auto px-2">

                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">

                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Total Runs
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Output Words
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Time Saved
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Rank
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                   Modified At
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {

                                                data !== null && data.map((e) => {

                                                    //if status is training, increase on training value

                                                    return (
                                                        <tr key={e.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {e.name}
                                                            </th>

                                                            <td className="px-6 py-4">
                                                                {e.runs}
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                <GreenBadge
                                                                    text={formatNum(e.words)}
                                                                />
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                {e.minSaved} Mins
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                <div className="mb-1">{e.rank}</div>
                                                                <LinearProgress percentage={e.rank} />
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                {dateFormat(e.updatedAt)}
                                                            </td>

                                                            <td className="px-6 py-4">

                                                                <div className="flex">

                                                                    <Link to={"/prompts/update/" + e.id}><AiOutlineEdit title="Edit" color="gray" className="mr-2" size={20} /></Link>

                                                                    <BiCopy size={20} title="Copy" className="mr-2" onClick={() => copyPrompt(e.id)} color="gray" />

                                                                    <AiOutlineShareAlt size={20} title="Share" className="mr-2" onClick={() => sharePrompt(e.id)} color="gray" />

                                                                    <AiOutlineDelete title="Delete"  size={20} onClick={() => dltPrompt(e.id)} color="gray" />

                                                                   
                                                                </div>

                                                            </td>


                                                        </tr>
                                                    )

                                                })
                                            }



                                        </tbody>

                                    </table>
                                </div>
                            </>
            }

        </Main>
    );
}

export default Prompts;